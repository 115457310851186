import clsx from "clsx";

const Loading = (props) => {
  const { className } = props;
  return (
    <div className={clsx("loading flex items-center gap-1", className)}>
      <span className="loading__dot"></span>
      <span className="loading__dot"></span>
    </div>
  );
};

export default Loading;
