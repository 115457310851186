import clsx from "clsx";
import { ButtonHTMLAttributes, ReactNode } from "react";
import Loading from "./Loading";

export interface IMeeyButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "small" | "medium" | "large";
  btnType?: "filled" | "outline" | "link";
  color?: "primary" | "secondary" | "default" | "danger" | "white" | "grey";
  children?: ReactNode;
  suffixIcon?: ReactNode;
  prefixIcon?: ReactNode;
  className?: string;
  loading?: boolean;
}

export default function Button({
  size = "large",
  btnType = "outline",
  color = "primary",
  children,
  prefixIcon,
  suffixIcon,
  className,
  loading,
  ...otherProps
}: IMeeyButtonProps) {
  return (
    <button
      className={clsx(
        "meey-button ",
        prefixIcon ? "meey-button-prefix-icon" : "",
        suffixIcon ? "meey-button-suffix-icon" : "",
        children ? "" : "meey-button-icon-only",
        `meey-button-${size}`,
        `meey-button-${btnType}`,
        `meey-button-${color}`,
        className
      )}
      aria-label={`${children}`}
      name={`${children}`}
      {...otherProps}>
      {loading ? <Loading className="mr-2" /> : null}
      {prefixIcon}
      {children}
      {suffixIcon}
    </button>
  );
}
