import { STATIC_DOMAIN } from "./environment";

export const DEFAULT_IMAGE_URI =
  "/meeyproject-test/images/2022/12/20/banner-home.1671526397059.jpg";

export const DEFAULT_IMAGE_CARD = STATIC_DOMAIN + "/anonymous.svg";

export const DEFAULT_USER_AVATAR = `images/default_user_avatar.svg`;

export const MAX_IMAGE_CARD = 10;

export const RESIZE_IMAGE = {
  default: {
    width: 720,
    height: 540,
  },
  card: {
    width: 404,
    height: 270,
  },
  cardMobile: {
    width: 382,
    height: 254,
  },
  cardHorizontal: {
    width: 320,
    height: 180,
  },
  cardProject: {
    width: 618,
    height: 412,
  },
  bannerDesktop: {
    width: 1920,
    height: 612,
  },
  bannerMobile: {
    width: 414,
    height: 200,
  },
  cardProjectMobile: {
    width: 382,
    height: 255,
  },
  cardNews: {
    width: 281,
    height: 168,
  },
  cardNewsFeatured: {
    width: 487,
    height: 292,
  },
  cardArticleHorizontal: { width: 404, height: 270 },
  cardArticleHorizontalFeature: { width: 404, height: 560 },
  cardArticleDetail: {
    width: 459,
    height: 274,
  },
  projectInfo: { width: 249, height: 149 },
  cardArticleByLocation: { width: 183, height: 183 },
};

export const NOT_FOUND_PROJECT_IMAGE = "/building.svg";

export const CopyIcon = `/images/social/copy.svg`;
export const FacebookIcon = `/images/social/facebook.svg`;
export const MessengerIcon = `/images/social/messenger.svg`;
export const ZaloIcon = `/images/social/zalo.svg`;
export const WhatsAppIcon = `/images/social/whatsapp.svg`;
export const TelegramIcon = `/images/social/telegram.svg`;
export const ViberIcon = `/images/social/viber.svg`;
