export const MIN_ROUND_NUMBER = 0.5;

export const LIMIT_DATALOAD_LIST = 10;
export const PAGE_SIZE = 30;

export const LIMIT_PROVINCE = 100;

export const LIMIT_LOCATION = 500;

export const PAGE_INDEX = 0;

export const MAX_PAGE = 50000;

export const LIMIT_PAGE_INDEX = 50000;

export const FEATURE_ARTICLE_LISTING = 2;

export const MAX_LENGTH_DESCRIPTION = 150;

export const LIMIT_FEATURE_PROJECT_HOME = 5;

export const LIMIT_RELATED_ARTICLE = 12;

export const LIMIT_RELATED_PROJECT = 6;

export const LIMIT_RELATED_ARTICLE_MOBILE = 10;

export const NUMBER_SKELETON_ARTICLE = 10;

export const SAVED_ARTICLE_PAGING = 3;

export const WATCHED_ARTICLE_PAGING = 5;

export const LIMIT_NEWS_FEATURE = 4;

export const LIMIT_FEATURE_PROJECT = 6;

export const LIMIT_FEATURE_PROJECT_LISTING = 6;

export const PAGE_SIZE_REVIEWS = 30;
export const PAGE_SIZE_REPLY = 10;

export const PAGE_SIZE_FILE = 30;
export const PAGE_SIZE_LIST_SEARCH = 20;
