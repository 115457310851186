import REQUEST_METHOD from "@/constants/request-method";
import { createFetcher } from "@/api/utils/fetch-api";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
const getNumberNewReview = async (projectId: string) => {
  const path = `v1/comments/project/${projectId}/countreview`;

  const response = await createFetcher<any>(() => ({
    apiUrl: MEEY_REVIEW_API_URL,
  }))(REQUEST_METHOD.GET, path, null, {});

  return response;
};

export default getNumberNewReview;
