export const APP_BASE_URL: string = process.env.APP_BASE_URL || "";
export const APP_ADMIN_URL: string = process.env.APP_ADMIN_URL || "";
export const APP_DOMAIN: string = process.env.APP_DOMAIN || "";

export const STATIC_DOMAIN: string = process.env.STATIC_DOMAIN || "";

export const MEEY_PROJECT_API_URL: string =
  process.env.MEEY_PROJECT_API_URL || "";
export const APP_MEEYPROJECT_URL: string =
  process.env.APP_MEEYPROJECT_URL || "";
export const MESSENGER_APPID: string = process.env.MESSENGER_APPID || "";

export const MEEY_REVIEW_API_URL: string =
  process.env.MEEY_REVIEW_API_URL || "";

export const MEEY_SEO_API_URL: string = process.env.MEEY_SEO_API_URL || "";

export const API_MEDIA_URL: string = process.env.API_MEDIA_URL || "";
export const MEDIA_URL: string = process.env.MEDIA_URL || "";
export const MEEY_HOME_API_URL: string = process.env.MEEY_HOME_API_URL || "";

export const NO_SEO_ROBOTS_CONTENT: boolean =
  process.env.NO_SEO_ROBOTS_CONTENT === "true";

export const ALLOW_ROBOTS: string = process.env.ALLOW_ROBOTS || "";

export const FB_APP_ID: string = process.env.FB_APP_ID || "";
export const SDK_URL: string = process.env.SDK_URL || "";

//tracking ga
export const GTM_CODE: string = process.env.GTM_CODE || "";
export const ENABLE_GTM: boolean = process.env.ENABLE_GTM === "true";
export const CAPCHA_KEY = process.env.CAPCHA_KEY;
