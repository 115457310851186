const path = require("path");
import { fileType } from "@/constants/file";
import CryptoJS from "crypto-js";
import { IResizeOnFlyOption } from "Models";
const replaceSafeBase64 = (input: any) => {
  return input.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
};

// resize on the fly
const urlSafeBase64 = (str: string) => {
  return replaceSafeBase64(Buffer.from(str).toString("base64"));
};

const sign = (salt: any, target: any, secret: any) => {
  const hmac = CryptoJS.algo.HMAC.create(
    CryptoJS.algo.SHA256,
    CryptoJS.enc.Hex.parse(secret)
  );
  hmac.update(CryptoJS.enc.Hex.parse(salt));
  hmac.update(target);
  return replaceSafeBase64(hmac.finalize().toString(CryptoJS.enc.Base64));
};

const config = {
  resize: {
    key: "81d04ff7ceb59c154647cbb173751d137ec6bf059f30b37d37ec043e4c859639",
    salt: "5a71c31c96ec7650b883aa294d89d1b38e5918bee141b977e18db43fc09552bc",
    domain: "https://i.meeymedia.com",
    domainWatermark: "https://i-homes.meeymedia.com",
  },
};

export const resizeOnFlyUrl = (resizeBody: IResizeOnFlyOption) => {
  const {
    width,
    height,
    resizingType,
    uri,
    gravity,
    watermark = false,
  } = resizeBody || {};

  const resizeType =
    resizingType === "crop" ? resizingType : `rs:${resizingType}`;

  const KEY = config.resize.key;
  const SALT = config.resize.salt;
  const domain =
    watermark && config.resize.domainWatermark
      ? config.resize.domainWatermark
      : config.resize.domain;

  const sourceImage = `s3:/${uri}`;
  const extension = path.extname(sourceImage)?.toLowerCase();
  const encodedUrl = urlSafeBase64(sourceImage);
  const pathGenerate = watermark
    ? `/${resizeType}:${width}:${height}/g:${gravity}/watermark:0.5:ce:0:0:0.2/${encodedUrl}${extension}`
    : `/${resizeType}:${width}:${height}/g:${gravity}/${encodedUrl}${extension}`;

  const signature = sign(SALT, pathGenerate, KEY);

  return `${domain}/${signature}${pathGenerate}`;
};

export const isImageFile = (name: string) => name.match(fileType.image);
export const isDocFile = (name: string) => name.match(fileType.file);
export const isVideoFile = (name: string) => name.match(fileType.video);
