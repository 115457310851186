export function emptyChecker(
  mixedVar: {},
  emptyValues = [undefined, null, ""]
) {
  let i, len;
  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true;
    }
  }
  if (typeof mixedVar === "object") {
    for (const item of Object.values(mixedVar)) {
      if (!emptyChecker(item, emptyValues)) {
        return false;
      }
    }
    return true;
  }
  return false;
}
export const isServer = typeof window === "undefined";

export const getShortId = (slug: string): number =>
  Number(slug.split("-").pop());

export const renderingText = (
  label: string | number,
  suffix?: string,
  prefix?: string
) =>
  !label
    ? "---"
    : `${prefix ? prefix + " " : ""}${label}${suffix ? " " + suffix : ""}`;
export const objectToGetParams = (object: {
  [key: string]: string | number | undefined | null;
}) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );
  return params.length > 0 ? `?${params.join("&")}` : "";
};
export const getAvatarName = (name?: string) => {
  return name?.split(" ").slice(-1).join(" ").charAt(0).toUpperCase();
};
export const copyToClipBoard = (
  text: string,
  callback?: Function,
  fallback?: Function
) => {
  if (navigator && navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (callback) {
          callback();
          return;
        }
      })
      .catch((err) => {
        fallback && fallback(err);
      });
  }
};
export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

export const isExpiredDate = (date: string | number | Date) => {
  return new Date(date).getTime() <= new Date().getTime();
};
export const fomartNumberSpretor = (val: string | number, spreator: " ") => {
  var withCommas = [];
  const valString = val ? `${val}` : "";
  for (var i = valString.length - 1; i >= 0; i--) {
    withCommas.push(valString[i]);
    if ((valString.length - i) % 3 === 0 && i !== 0) {
      withCommas.push(" ");
    }
  }
  return withCommas.reverse().join("");
};

export const voteCategoryColor = (vote: number) => {
  let color = "";
  switch (true) {
    case vote < 2:
      color = "#CE3217";
      break;
    case vote >= 2 && vote < 3:
      color = "#FEA855";
      break;
    case vote >= 3 && vote < 4.5:
      color = "#65C764";
      break;
    default:
      color = "#27A435";
  }
  return color;
};
export const skipNullParams = (data: any) => {
  let editData = {};
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      editData[key] = data[key];
    }
  });
  return editData;
};
