import { createFetcher } from "@/api/utils/fetch-api";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import REQUEST_METHOD from "@/constants/request-method";
import { BaseResultListResponse, ListFileProjectReview } from "Models";
import { MediaListParams } from "Params";
import qs from "query-string";
import urlJoin from "url-join";

export const getListFileReviewProject = async (
  project: string,
  params?: MediaListParams
) => {
  const path = urlJoin(
    `v1/media/project/${project}`,
    `?${qs.stringify(params, { skipNull: true })}`
  );

  const response = await createFetcher<BaseResultListResponse<ListFileProjectReview>>(
    () => ({
      apiUrl: MEEY_REVIEW_API_URL,
    })
  )(REQUEST_METHOD.GET, path, null, {});

  return response;
};
