import { BaseItem } from "Models";

export interface IAdressObject {
  street?: BaseItem;
  ward?: BaseItem;
  district?: BaseItem;
  city?: BaseItem;
  address?: string;
}
export const convertAddress = (addressObject: IAdressObject) => {
  const streetTranslate = addressObject.street;
  const wardTranslate = addressObject.ward;
  const districtTranslate = addressObject.district;
  const cityTranslate = addressObject.city;

  return `${addressObject.address ? addressObject.address : ""} ${
    streetTranslate?.name.trim() ? streetTranslate.name + "," : ""
  } ${wardTranslate?.name?.trim() ? wardTranslate?.name + "," : ""} 
  ${districtTranslate?.name?.trim() ? districtTranslate?.name + "," : ""} 
  ${cityTranslate?.name?.trim() ? cityTranslate?.name : ""}`;
};

export const convertAddressNoStreet = (addressObject: IAdressObject) => {
  const wardTranslate = addressObject.ward;
  const districtTranslate = addressObject.district;
  const cityTranslate = addressObject.city;

  return `${addressObject.address ? addressObject.address : ""} ${
    wardTranslate?.name?.trim() ? wardTranslate?.name + "," : ""
  } 
  ${districtTranslate?.name?.trim() ? districtTranslate?.name + "," : ""} 
  ${cityTranslate?.name?.trim() ? cityTranslate?.name : ""}`;
};

export const shortAddress = (
  addressObject: IAdressObject,
  showPrefix = true
) => {
  // if (!addressObject) return ''
  // const districtTranslate = getTranslateItemByCurrentLanguage<
  // 	Location,
  // 	LocationTranslation
  // >(addressObject.district)
  // const cityTranslate = getTranslateItemByCurrentLanguage<
  // 	Location,
  // 	LocationTranslation
  // >(addressObject.city)
  // return `
  // ${
  // 	districtTranslate?.name?.trim()
  // 		? (/^\d*$/.test(districtTranslate.name) || showPrefix
  // 				? districtTranslate.prefix + ' '
  // 				: '') +
  // 		  districtTranslate?.name +
  // 		  ','
  // 		: ''
  // }
  // ${
  // 	cityTranslate?.name?.trim()
  // 		? (showPrefix ? cityTranslate.prefix + ' ' : '') + cityTranslate?.name
  // 		: ''
  // }`
};
