import getNumberNewReview from "@/api/endpoint/project/get-number-new-review";
import { getListFileReviewProject } from "@/api/endpoint/reviews/get-list-file-review-project";
import { MediaTypeEnum } from "@/constants/enum/media";
import { PAGE_SIZE_FILE } from "@/constants/number";
import {
  BaseDataListResponse,
  FileS3,
  IReviewItem,
  IReviewReactions,
  ListFileProjectReview,
  Project,
  ProjectReview,
  VideoItem,
} from "Models";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
interface IProjectContext {
  project?: ProjectReview;
  setProject?: Dispatch<SetStateAction<Project>>;
  projectListVote?: BaseDataListResponse<IReviewItem>;
  setProjectListVote?: Dispatch<
    SetStateAction<BaseDataListResponse<IReviewItem>>
  >;
  currentShowFormReply?: string;

  setCurrentShowFormReply?: Dispatch<SetStateAction<string>>;
  currentShowFormReport?: string;

  setCurrentShowFormReport?: Dispatch<SetStateAction<string>>;

  selectedVoteFiles?: {
    currentFile: FileS3;
    files: FileS3[];
  };

  setSelectedVoteFiles?: Dispatch<
    SetStateAction<{
      currentFile: FileS3;
      files: FileS3[];
    }>
  >;
  listReplyVote?: {
    [key: string]: BaseDataListResponse<IReviewItem>;
  };
  setListReplyVote?: Dispatch<
    SetStateAction<{
      [key: string]: BaseDataListResponse<IReviewItem>;
    }>
  >;
  projectListFile?: BaseDataListResponse<ListFileProjectReview>;

  setProjectListFile?: Dispatch<
    SetStateAction<BaseDataListResponse<ListFileProjectReview>>
  >;

  selectedViewFiles?: {
    currentFile: ListFileProjectReview;
    files: ListFileProjectReview[];
    maxFileShow?: number;
  };

  setSelectedViewFiles?: Dispatch<
    SetStateAction<{
      currentFile: ListFileProjectReview;
      files: ListFileProjectReview[];
      maxFileShow?: number;
    }>
  >;
  checkFollowProject?: boolean;

  setCheckFollowProject?: Dispatch<SetStateAction<boolean>>;
  numberNewReview?: {
    "1": number;
    "2": number;
    "3": number;
    "4": number;
    "5": number;
    totalReview: number;
    totalQuestion: number;
    totalReply: number;
    media: number;
    today: number;
  };
  setNumberNewReview?: Dispatch<
    SetStateAction<{
      "1": number;
      "2": number;
      "3": number;
      "4": number;
      "5": number;
      totalReview: number;
      totalQuestion: number;
      totalReply: number;
      media: number;
      today: number;
    }>
  >;
  reviewDetail?: IReviewItem[];
  setReviewDetail?: Dispatch<SetStateAction<IReviewItem[]>>;
  handleSyncNumberReview?: () => void;
  loadingLike?: boolean;
  setLoadingLike?: Dispatch<SetStateAction<boolean>>;
  loadingDisLike?: boolean;
  setLoadingDisLike?: Dispatch<SetStateAction<boolean>>;
  topFileMedia?: ListFileProjectReview[];
  setTopFileMedia?: Dispatch<SetStateAction<ListFileProjectReview[]>>;
  listReviewsIds?: string[];
  setListReviewIds?: Dispatch<SetStateAction<string[]>>;
  listReviewReactions?: IReviewReactions[];
  setListReviewReactions?: Dispatch<SetStateAction<IReviewReactions[]>>;
  refUpload?: any;
  loadingSendReply?: boolean;
  setLoadingSendReply?: Dispatch<SetStateAction<boolean>>;
  currentRefItem?: MutableRefObject<HTMLDivElement[]>;
  projectVideos?: VideoItem[];
  setProjectVideos?: Dispatch<SetStateAction<VideoItem[]>>;
}

interface ListingContextProviderProps extends IProjectContext {
  children: React.ReactNode;
}

export const ProjectContext = createContext<IProjectContext>({
  project: null,
  projectListVote: {
    page: 1,
    totalPages: 0,
    totalResults: 0,
    results: [],
  },
  listReplyVote: {},
  projectListFile: {
    page: 1,
    totalPages: 0,
    totalResults: 0,
    results: [],
  },
});

export function ProjectContextProvider(
  props: ListingContextProviderProps
): JSX.Element {
  const { children, ...rest } = props;
  const [project, setProject] = useState(rest.project);
  const [projectVideos, setProjectVideos] = useState(rest.projectVideos);
  const [projectListVote, setProjectListVote] = useState(rest.projectListVote);
  const [projectListFile, setProjectListFile] = useState(rest.projectListFile);
  const [currentShowFormReply, setCurrentShowFormReply] = useState(null);
  const [currentShowFormReport, setCurrentShowFormReport] = useState(null);
  const [listReplyVote, setListReplyVote] = useState<{
    [key: string]: BaseDataListResponse<IReviewItem>;
  }>({});
  const [selectedVoteFiles, setSelectedVoteFiles] = useState<{
    currentFile: FileS3;
    files: FileS3[];
  }>(null);

  // set selected file for right content
  const [selectedViewFiles, setSelectedViewFiles] = useState<{
    currentFile: ListFileProjectReview;
    files: ListFileProjectReview[];
  }>(null);

  const [checkFollowProject, setCheckFollowProject] = useState(false);

  const [loadingLike, setLoadingLike] = useState(false);
  const [loadingDisLike, setLoadingDisLike] = useState(false);
  const [loadingSendReply, setLoadingSendReply] = useState(false);

  const [numberNewReview, setNumberNewReview] = useState(rest.numberNewReview);
  const [reviewDetail, setReviewDetail] = useState(rest.reviewDetail);
  const [topFileMedia, setTopFileMedia] = useState([]);
  const [listReviewsIds, setListReviewIds] = useState([]);
  const [listReviewReactions, setListReviewReactions] = useState([]);
  const refUpload = useRef(null);
  const currentRefItem = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    setProjectListVote(rest.projectListVote);
    if (rest?.projectListVote) {
      let listIds = [];
      rest.projectListVote?.results?.map((item) => {
        listIds.push(item?._id);
        if (item.replies?.length) {
          item.replies.map((rep) => {
            listIds.push(rep._id);
          });
        }
      });
      setListReviewIds(listIds);
      setListReplyVote({});
    }
  }, [rest.projectListVote]);

  useEffect(() => {
    setReviewDetail(rest.reviewDetail);
    if (rest?.reviewDetail) {
      let listIds = [];
      listIds.push(rest?.reviewDetail[0]?._id);
      if (rest.reviewDetail[0]?.replies?.length) {
        rest.reviewDetail[0]?.replies?.map((item) => {
          listIds.push(item?._id);
        });
      }
      setListReviewIds(listIds);
    }
  }, [rest.reviewDetail]);

  useEffect(() => {
    setProject(rest.project);
    setProjectListFile(rest.projectListFile);
    setNumberNewReview(rest.numberNewReview);
  }, [
    rest.project,
    rest.project._id,
    rest.projectListFile,
    rest.numberNewReview,
  ]);

  useEffect(() => {
    if (rest.projectListFile) {
      setTopFileMedia(rest?.projectListFile?.results);
    }
  }, [rest.projectListFile]);

  useEffect(() => {
    setProjectVideos(rest.projectVideos);
  }, [rest.projectVideos]);
  const handleSyncNumberReview = useCallback(async () => {
    try {
      const resListFile = await getListFileReviewProject(project?._id, {
        type: `${MediaTypeEnum.IMAGE.toString()},${MediaTypeEnum.VIDEO.toString()},${MediaTypeEnum.FILE.toString()}`,
        page: 1,
        limit: PAGE_SIZE_FILE,
      });
      if (resListFile) {
        setProjectListFile(resListFile);
        setTopFileMedia(resListFile?.results);
      }
      const res = await getNumberNewReview(project._id);
      setNumberNewReview(res);
    } catch (error) {
      console.log(error);
    }
  }, [project]);

  return (
    <ProjectContext.Provider
      value={{
        ...rest,
        projectListVote: projectListVote,
        selectedVoteFiles,
        listReplyVote,
        selectedViewFiles,
        projectListFile,
        project,
        checkFollowProject,
        numberNewReview,
        reviewDetail,
        currentShowFormReply,
        loadingLike,
        loadingDisLike,
        topFileMedia,
        listReviewsIds,
        listReviewReactions,
        refUpload,
        loadingSendReply,
        currentShowFormReport,
        projectVideos,
        setProjectVideos,
        setCurrentShowFormReport,
        setLoadingSendReply,
        setCurrentShowFormReply,
        setListReplyVote,
        setSelectedVoteFiles,
        setProjectListVote,
        setSelectedViewFiles,
        setProjectListFile,
        setProject,
        setCheckFollowProject,
        setNumberNewReview,
        setReviewDetail,
        handleSyncNumberReview,
        setLoadingLike,
        setLoadingDisLike,
        setTopFileMedia,
        setListReviewIds,
        setListReviewReactions,
        currentRefItem,
      }}>
      {props.children}
    </ProjectContext.Provider>
  );
}
