export enum VoteCategoryEnum {
  /** Vị trí */
  LOCATION = 1,
  /** Chi phí */
  EXPENSE = 2,
  /** Pháp lý */
  LEGAL = 3,
  /** Dịch vụ */
  SERVICE = 4,
  /** Tiện ích */
  UTILITIES = 5,
  /** Chủ đầu tư */
  INVESTOR = 6,
  /** BQL Dự án */
  PROJECT = 7,
  /** Chất lượng */
  QUALITY = 8,
}
