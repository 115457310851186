import { VoteCategoryEnum } from "./enum/vote";
export const MAX_LIST_FILE_SHOW = 10;
export const PROJECT_PROPERTIES = {
  PROECT_TITLE: "title",
  PROJECT_TYPES: "projectTypes",
  STATUS: "handoverProgress",
  // PROGRESS: 'progress',
  //INVESTOR: 'investor',
  LOCATION: "location",
  //OPERATING_UNIT: 'operatingUnit',
  BUILD_DENSITY: "buildingDensity",
  OWNER_SHIP: "ownership",
  TOTAL_BUILDING: "totalBuilding",
  TOTAL_FLOOR: "totalFloor",
  TOTAL_APARTMENT: "totalApartment",
  // LEGAL: 'legal',
  START_TIME: "startTime",
  COMPLETION_TIME: "completionTime",
  PRICE: "price",
  AREA: "AREA",
};
export const PROJECT_PROPERTIES_LABEL = {
  [PROJECT_PROPERTIES.PROECT_TITLE]: "Tên dự án",
  [PROJECT_PROPERTIES.PROJECT_TYPES]: "Loại dự án",
  [PROJECT_PROPERTIES.STATUS]: "Trạng thái dự án",
  [PROJECT_PROPERTIES.STATUS]: "Tiến độ bàn giao",
  //INVESTOR: 'investor',
  [PROJECT_PROPERTIES.LOCATION]: "Vị trí",
  //OPERATING_UNIT: 'operatingUnit',
  [PROJECT_PROPERTIES.BUILD_DENSITY]: "Mật độ xây dựng",
  [PROJECT_PROPERTIES.OWNER_SHIP]: "Hình thức sở hữu",
  [PROJECT_PROPERTIES.TOTAL_BUILDING]: "Số tòa",
  [PROJECT_PROPERTIES.TOTAL_FLOOR]: "Số tầng",
  [PROJECT_PROPERTIES.TOTAL_APARTMENT]: "Số căn",
  // LEGAL: 'legal',
  [PROJECT_PROPERTIES.START_TIME]: "Thời gian khởi công",
  [PROJECT_PROPERTIES.COMPLETION_TIME]: "Thơi gian hoàn thành",
  [PROJECT_PROPERTIES.PRICE]: "Mức giá từ",
  [PROJECT_PROPERTIES.AREA]: "Diện tích",
};

export const DOCUMENT_RELATED = {
  BANK: "bank",
  CONSTRUCTION_UNIT: "constructionUnit",
  OPERATING_UNIT: "operatingUnit",
  DISTRIBUTION_UNIT: "distributionUnit",
};

export const PROJECT_RELATED_VIDEO = {
  DEFAULT_LIMIT_DESKTOP: 4,
  DEFAULT_LIMIT_TABLET: 6,
  DEFAULT_LIMIT_MOBILE: 5,
  LIMIT_LOAD_MORE_DESKTOP: 4,
  LIMIT_LOAD_MORE_TABLET: 3,
  LIMIT_LOAD_MORE_MOBILE: 5,
  LIMIT_MAX_VIDEO: 60,
};

export const JURIDICAL_DOCUMENTS = {
  BUSINESS_LICENSE: "businessLicense",
  CONSTRUCTION_PERMIT: "constructionPermit",
  HANDOVER_DECISION: "handoverDecision",
  RESPONSIBLE_FOR_COLLECTION_FOUNDATION: "responsibleForCollectingFoundation",
  OWNER_SHIP_CERTIFICATE: "ownershipCertificate",
  BANK_GUARANTEE: "bankGuarantee",
  APPROVE1_500: "approve1_500",
  OTHER_DOCS: "otherDocs",
};
export const DEFAULT_IMAGE_URI =
  "/meeycrm-v3-test/images/2023/06/07/202302021420517b57wm.1686126016669.jpg";
export const RATE_CATEGORY = [
  {
    title: "Vị trí",
    key: VoteCategoryEnum.LOCATION,
  },
  {
    title: "Tiện ích",
    key: VoteCategoryEnum.UTILITIES,
  },
  {
    title: "Giá bán",
    key: VoteCategoryEnum.EXPENSE,
  },
  {
    title: "Chất lượng ",
    key: VoteCategoryEnum.QUALITY,
  },
  {
    title: "Dịch vụ",
    key: VoteCategoryEnum.SERVICE,
  },
  {
    title: "BQL dự án",
    key: VoteCategoryEnum.PROJECT,
  },
  {
    title: "Pháp lý",
    key: VoteCategoryEnum.LEGAL,
  },

  {
    title: "Chủ đầu tư",
    key: VoteCategoryEnum.INVESTOR,
  },
];

export const DEFAULT_LOGO_PROJECT = `images/default_avatar_project.jpg`;
